import './PageSectionBackgroundSlides.scss';

import { graphql } from 'gatsby';
import { Swiper as ReactSwiper, SwiperSlide } from 'swiper/react';
import React from 'react';

import "swiper/css";
import "swiper/css/lazy";

import { WpFrontPageBgSlide } from '../../../types/wordpress.types';
import { useStore } from '../../../utils/mobx.utils';
import BaseImage from '../../BaseImage/BaseImage';
import { useOnMount } from '../../../hooks/lifecycle.hooks';
import { action } from 'mobx';
import { Lazy, Autoplay } from 'swiper';
import PageSection from '../../PageSection/PageSection';

export const PageSectionBackgroundSlidesFragment = graphql`
  fragment PageSectionBackgroundSlidesFragment on WpTemplate_FrontPage {
    frontPageFields {
      sectionBackgroundSlide {
        body
        heading
        subheading
        backgroundSlideImages {
          ...Image_MediaItem
        }
      }
    }
  }
`;

type PageSectionBackgroundSlidesProps = {
  bgSlides: WpFrontPageBgSlide[],
  ArticleContent: React.ReactNode,
}

const PageSectionBackgroundSlides = (props: PageSectionBackgroundSlidesProps) => {
  const p = props;
  const s = useStore(() => ({
    _sizeLgStr: "" as string,
    get sizeLg() { return (parseFloat(s._sizeLgStr) || 5.2) * 10; },
    get swiperConfig() {
      return {
        speed: 15000,
        spaceBetween: s.sizeLg * 1.5,
        loop: true,
        noSwiping: true,
        freeMode: {
          enabled: true,
          momentumVelocityRatio: 1,
        },
        lazy: true,
        slidesPerView: 1,
        autoplay: {
          delay: 0,
          disableOnInteraction: false,
        },
        breakpoints: {
          768: {
            speed: 25000,
            slidesPerView: 1.62,
          },
          1440: {
            speed: 27500,
            slidesPerView: 2,
          }
        },
        modules: [Lazy, Autoplay],
      }
    },
  }))
  useOnMount(() => {
    action(() => {
      s._sizeLgStr = window.getComputedStyle(document.body).getPropertyValue('--size-lg') as string;
    })()
  })
  return <PageSection className="page-section SectionBackgroundSlides" id="SectionBackgroundSlides" observeVisibility>
    <div className="container">

      <article className="SectionBackgroundSlides__article">
        {p.ArticleContent}
      </article>

      {p.bgSlides && (
        <div className="SectionBackgroundSlides-slides on" id="SectionBackgroundSlides-slides">
          <ReactSwiper {...s.swiperConfig} className="SectionBackgroundSlides-swiper">
            {p.bgSlides.map((slide) => (
              <SwiperSlide key={slide.id}>
                <figure className="SectionBackgroundSlides-slides__slide swiper-slide">
                  <div className="SectionBackgroundSlides-slides__slide__inner">
                    <BaseImage
                      className="filler SectionBackgroundSlides-slides__slide__image"
                      media={slide}
                      imageType='gatsbyDynamic'
                    />
                  </div>
                </figure>
              </SwiperSlide>
            ))}
          </ReactSwiper>
        </div>
      )}
    </div>

  </PageSection>
}

export default PageSectionBackgroundSlides;